<template>
  <div>
    <loader />
  </div>
</template>

<script>
import LoaderMixin from '@/mixins/LoaderMixin'
import settings from '@/settings'

export default {
  name: 'AuthCallback',
  mixins: [LoaderMixin],
  created () {
    // Redirect to home page after password reset
    if (this.$route.query[settings.app.queryTokens.resetPasswordCanceledToken]) {
      this.$router.replace('/')
    }
  }
}
</script>
